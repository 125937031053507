<div [formGroup]="form" class="form-group">
  <mat-divider></mat-divider>
  <h4>{{ input.question }}: {{ input.isRequired === 1 ? '*' : '' }}</h4>

  <div [ngSwitch]="input.questionElementType">

    <div
      class="notes"
      *ngSwitchCase="questionType.TEXTAREA"
    >
      <mat-form-field
        class="full-width"
        appearance="standard"
      >
        <textarea
          matInput
          rows="5"
          [placeholder]="getHelpTextForTextAreaFieldInput(input)"
          [formControlName]="input.id"
          [required]="input.isRequired === 1"
        ></textarea>
        <mat-error *ngIf="form.controls[input.id].errors?.required && form.controls[input.id].touched">This field is required.</mat-error>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="questionType.RADIOBUTTON">
      <mat-radio-group
        [formControlName]="input.id"
        class="group"
        [required]="input.isRequired === 1"
      >
        <mat-radio-button
          *ngFor="let option of input.questionOptions"
          [value]="option"
        >
          {{ option }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="form.controls[input.id].errors?.required && form.controls[input.id].touched">This field is required.</mat-error>
    </div>

    <div
      *ngSwitchCase="questionType.CHECKBOX"
      class="group"
    >
      <mat-checkbox
        *ngFor="let option of input.questionOptions"
        [formControlName]="input.id"
        [required]="input.isRequired === 1"
      >{{ option }}</mat-checkbox>
      <mat-error *ngIf="form.controls[input.id].errors?.required && form.controls[input.id].touched">This field is required.</mat-error>
    </div>

	<div *ngSwitchCase="questionType.MULTISELECT">
		<mat-selection-list [formControlName]="input.id" class="group multiselect-field">
			<mat-list-option *ngFor="let option of input.questionOptions" [value]="option" checkboxPosition="before" class="list-option">
				{{ option }}
			</mat-list-option>
		  </mat-selection-list> 
		<mat-error *ngIf="form.controls[input.id].errors?.required && form.controls[input.id].touched">
			This field is required.
		</mat-error>
	</div>

    <app-msg-banner *ngIf="showNotification" [msgList]="messageList"
                    (msgListChange)="messageList = $event"></app-msg-banner>

    <div
      *ngSwitchCase="null"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="5px"
      fxLayoutGap.lt-md="0">
      <input
        #fileForm
        type="file"
        multiple
        (change)="onFileChange($event, input.id)"
        [required]="input.isRequired === 1"
        accept="image/jpeg,image/png"
      >
      <mat-error *ngIf="form.controls[input.id].hasError('required') && form.controls[input.id].touched">This field is required.</mat-error>
      <div>
        <button
          *ngIf="actualImages?.length < 3"
          mat-raised-button
          class="btn btn-blue"
          fxFlex="140px"
          fxFlex.lt-md="100%"
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="fileForm.click()"
          [disabled]="readOnly"
        >
          <mat-icon>file_upload</mat-icon>
          Upload
        </button>
      </div>

      <div *ngIf="actualImages?.length < 3 && isDesktopDevide()">
        <button
          mat-raised-button
          class="btn btn-blue"
          fxFlex="140px"
          fxFlex.lt-md="100%"
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="openCamera()"
          [disabled]="readOnly"
        >
          <mat-icon>photo_camera</mat-icon>
          Take Photo
        </button>
      </div>

    </div>

    <div
      class="file-holder"
      fxLayout="row"
    >
      <div
        class="image"
        [hidden]="img.deleted"
        *ngFor='let img of images; let i = index'
      >
        <img
          class="mini-img"
		  alt="mini-img"
          [src]="getFullImage(img)"
          [matTooltip]="img.name"
        >
        <button
          *ngIf="!readOnly"
          class="delete_button"
          mat-icon-button
          (click)="remove(i, input.id)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </div>

</div>
